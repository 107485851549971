body::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
  background: #171717;
}

body::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffd635;
  outline: 1px solid #ffcc00;
}

.hover-pointer-container:hover {
  cursor: pointer;
}

.profile-upload-container {
  width: 100%;
}
